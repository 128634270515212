import { Card, CardContent, Typography, Box, Chip } from "@mui/material"
import PersonIcon from "@mui/icons-material/Person"
import SecurityIcon from "@mui/icons-material/Security"

interface PersonalInfoCardProps {
  data?: {
    name?:
      | {
          qualifier?: string
          person?: {
            first: string
            middle: string
            last: string
            unparsed?: string
          }
        }
      | {
          qualifier?: string
          person?: {
            first: string
            middle: string
            last: string
            unparsed?: string
          }
        }[]
    dateOfBirth?: {
      "#text": string
    }
    socialSecurity?: {
      number?: string
    }
  }
}

const formatDate = (date?: string) => {
  if (!date) return ""
  return new Date(date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

const PersonalInfoCard = ({ data }: PersonalInfoCardProps) => {
  const maskSSN = (ssn: string) => {
    return `***-**-${ssn.slice(-4)}`
  }

  let nameArray = []
  if (Array.isArray(data?.name)) {
    nameArray = data?.name || []
  } else {
    nameArray = data?.name ? [data?.name] : []
  }

  return (
    <Card elevation={2}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <PersonIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Personal Information</Typography>
        </Box>
        <Box sx={{ display: "grid", gap: 2 }}>
          {nameArray?.map((name) => (
            <Box>
              <Typography color="text.secondary" gutterBottom>
                Full Name(s)
              </Typography>
              <Typography variant="body1">
                {name?.person?.first
                  ? `${name?.qualifier || ""} ${name?.person?.first} ${
                      name?.person?.middle || ""
                    } ${name?.person?.last}`
                  : `${name?.qualifier || ""} ${name?.person?.unparsed}`}
              </Typography>
            </Box>
          ))}
          <Box>
            <Typography color="text.secondary" gutterBottom>
              Date of Birth
            </Typography>
            <Typography variant="body1">
              {formatDate(data?.dateOfBirth?.["#text"])}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography color="text.secondary" gutterBottom sx={{ mr: 1 }}>
              Social Security Number
            </Typography>
            <Chip
              icon={<SecurityIcon />}
              label={data?.socialSecurity?.number}
              variant="outlined"
              size="small"
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default PersonalInfoCard
