import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Switch,
  Stack,
} from "@mui/material"
import { Formik, FormikProps } from "formik"
import { enqueueSnackbar } from "notistack"
import { usePatchInternalBusiness } from "src/queries/internal/usePatchInternalBusiness"
import { Business } from "src/types"

export default function ClientBusinessDetails({
  id,
  data,
  refetch,
}: {
  id: string
  data: Business
  refetch: () => void
}) {
  const { isLoading, execute } = usePatchInternalBusiness(() => {
    enqueueSnackbar("Business updated", { variant: "success" })
  })
  const PreferencesField = (props: FormikProps<Business>) => {
    return (
      <>
        <Stack direction="column" spacing={2}>
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="skipBankAuthorizationIfPlaid"
                checked={
                  props.values?.preferences
                    ?.skipBankAuthorizationIfPlaid as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["skipBankAuthorizationIfPlaid"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Do not add Bank Authorization form to Zoho Sign if buyer connects with Plaid"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="sendApprovalEmailsDefault"
                checked={
                  props.values?.preferences
                    ?.sendApprovalEmailsDefault as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["sendApprovalEmailsDefault"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Prefer sending approval emails to the buyer when application is being approved."
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="askNetTermsInTradeReference"
                checked={
                  props.values?.preferences
                    ?.askNetTermsInTradeReference as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["askNetTermsInTradeReference"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Ask for net terms in trade reference form."
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="approvalSummaryEmail"
                checked={
                  props.values?.preferences?.approvalSummaryEmail as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["approvalSummaryEmail"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Approval Summary Email for the assigned Credit Manager"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="tradeReferencePdf"
                checked={
                  props.values?.preferences?.tradeReferencePdf as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["tradeReferencePdf"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Attach the Trade Reference PDF to outgoing emails"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="manualBankReference"
                checked={
                  props.values?.preferences?.manualBankReference as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["manualBankReference"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Manual Bank Reference"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="salesCanModifyInviteTemplate"
                checked={
                  props.values?.preferences
                    ?.salesCanModifyInviteTemplate as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["salesCanModifyInviteTemplate"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Sales can modify the template of the application when inviting a buyer"
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="mergeTermsInFirstPage"
                checked={
                  props.values?.preferences?.mergeTermsInFirstPage === undefined
                    ? true
                    : (props.values?.preferences
                        ?.mergeTermsInFirstPage as boolean)
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["mergeTermsInFirstPage"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Merge Business Terms with NetNow Terms of Service in the User Registration page of the applications."
          />
          <FormControlLabel
            control={
              <Switch
                size="small"
                name="creditAgreementAfterData"
                checked={
                  props.values?.preferences?.creditAgreementAfterData as boolean
                }
                onChange={(e) => {
                  const preferences = props.values.preferences
                    ? props.values.preferences
                    : {}

                  preferences["creditAgreementAfterData"] = e.target.checked
                  props.setFieldValue("preferences", preferences)
                }}
              />
            }
            label="Put Credit Agreement pages after data in the generated application PDF"
          />
          <TextField
            name="notificationRecipients"
            label="Notification recipients"
            value={
              props.values?.preferences?.notificationRecipients
                ? typeof props.values?.preferences?.notificationRecipients ===
                  "string"
                  ? (props.values?.preferences
                      ?.notificationRecipients as string)
                  : (
                      props.values?.preferences
                        ?.notificationRecipients as string[]
                    ).join(",")
                : ""
            }
            onChange={(e) => {
              const preferences = props.values.preferences
                ? props.values.preferences
                : {}
              if (typeof e.target.value.includes(",")) {
                preferences["notificationRecipients"] =
                  e.target.value.split(",")
              } else {
                preferences["notificationRecipients"] = e.target.value as string
              }
              props.setFieldValue("preferences", preferences)
            }}
          />
          <TextField
            name="approvalEmailFields"
            label="Fields to show in the approval email (default: all)"
            value={
              props.values?.preferences?.approvalEmailFields
                ? (props.values?.preferences?.approvalEmailFields as string)
                    .length > 0
                  ? (
                      props.values?.preferences?.approvalEmailFields as string[]
                    ).join(",")
                  : ""
                : ""
            }
            onChange={(e) => {
              const preferences = props.values.preferences
                ? props.values.preferences
                : {}
              if (typeof e.target.value.includes(",")) {
                preferences["approvalEmailFields"] = e.target.value.split(",")
              } else {
                preferences["approvalEmailFields"] = [e.target.value as string]
              }
              props.setFieldValue("preferences", preferences)
            }}
          />
        </Stack>
      </>
    )
  }
  return (
    <>
      <Container sx={{ p: 2 }}>
        <Formik
          initialValues={data as Business}
          onSubmit={(values) => {
            if (!values.id) {
              values.id = id
            }
            execute(values, () => {
              refetch()
            })
          }}
        >
          {(props) => (
            <Grid direction={"row"} container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  id="name"
                  name="name"
                  margin="normal"
                  fullWidth
                  label="Name"
                  type="text"
                  value={props.values.name}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.name)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="email"
                  name="email"
                  margin="normal"
                  fullWidth
                  label="Email"
                  type="text"
                  value={props.values.email}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.email)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="street"
                  name="street"
                  margin="normal"
                  fullWidth
                  label="street"
                  type="text"
                  value={props.values.street}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.state)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="city"
                  name="city"
                  margin="normal"
                  fullWidth
                  label="City"
                  type="text"
                  value={props.values.city}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.city)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="postalCode"
                  margin="normal"
                  name="postalCode"
                  fullWidth
                  label="Postal Code"
                  type="text"
                  value={props.values.postalCode}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.postalCode)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="state"
                  margin="normal"
                  fullWidth
                  label="State"
                  type="text"
                  value={props.values.state}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.state)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                  required
                >
                  <InputLabel id="country-label">Country</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    label="Country"
                    name="country"
                    value={props.values.country}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.country)}
                  >
                    <MenuItem disabled value={undefined}>
                      Select one
                    </MenuItem>

                    {["United States", "Canada", "US", "CA"].map(
                      (value, index) => (
                        <MenuItem value={value} key={index}>
                          {value}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="shortenedUrl"
                  margin="normal"
                  fullWidth
                  label="Shortened Url"
                  type="text"
                  value={props.values.shortenedUrl}
                  onChange={props.handleChange}
                  error={Boolean(props.errors?.shortenedUrl)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                  required
                >
                  <InputLabel id="currency-label">Currency</InputLabel>
                  <Select
                    labelId="currency-label"
                    id="currency"
                    name="currency"
                    label="currency"
                    value={props.values.currency}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.currency)}
                  >
                    <MenuItem disabled value={undefined}>
                      Select one
                    </MenuItem>

                    {["USD", "CAD"].map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  style={{ flex: "1 1 0px" }}
                  required
                >
                  <InputLabel id="language-label">Language</InputLabel>
                  <Select
                    labelId="language-label"
                    id="language"
                    name="language"
                    label="Language"
                    value={props.values.language}
                    onChange={props.handleChange}
                    error={Boolean(props.errors?.language)}
                  >
                    <MenuItem disabled value={undefined}>
                      Select one
                    </MenuItem>

                    {["es", "en", "fr"].map((value, index) => (
                      <MenuItem value={value} key={index}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="color"
                  name="color"
                  label="Color (in HEX)"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                  }}
                  value={props.values.color}
                  onChange={props.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                {PreferencesField(props)}
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => {
                    props.handleSubmit()
                  }}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          )}
        </Formik>
      </Container>
    </>
  )
}
