import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
} from "@mui/material"
import { useCashUploadedFile } from "../../queries/cod/useCashUploadedFile"

// loads an image or pdf file using an UploadedFile id
export default ({
  fileId,
  onClose,
}: {
  fileId?: string
  onClose: () => void
}) => {
  const { data: fetchedDocument, isLoading } = useCashUploadedFile(fileId)

  return (
    <Dialog
      open={!!fileId}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      maxWidth={false}
    >
      <DialogTitle>{fetchedDocument?.name || "Loading..."}</DialogTitle>
      {(!fetchedDocument || isLoading) && (
        <DialogContent>Loading...</DialogContent>
      )}
      {fetchedDocument && (
        <>
          <DialogContent>
            <Box>
              {fetchedDocument.file.includes(".pdf") && (
                <iframe
                  src={fetchedDocument.file}
                  style={{
                    width: "1000px",
                    height: "75vh",
                  }}
                />
              )}
              {!fetchedDocument.file.includes(".pdf") &&
                !fetchedDocument.file.includes(".msg") && (
                  <img src={fetchedDocument.file} />
                )}
              {fetchedDocument.file.includes(".msg") && (
                <Box>
                  <p>
                    This file type is not supported by the browser. Please
                    download the file to view it.
                  </p>
                </Box>
              )}
            </Box>
          </DialogContent>
          {fetchedDocument.file.includes(".pdf") && (
            <DialogActions>
              <Box style={{ display: "flex", gap: "1rem" }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    const link = document.createElement("a")
                    link.href = fetchedDocument.file
                    link.download = fetchedDocument.file.substr(
                      fetchedDocument.file.lastIndexOf("/") + 1,
                    )
                    link.click()
                  }}
                >
                  Download
                </Button>
                <Button variant="contained" onClick={onClose}>
                  Close
                </Button>
              </Box>
            </DialogActions>
          )}
          {!fetchedDocument.file.includes(".pdf") && (
            <DialogActions>
              <Button
                onClick={() => {
                  const link = document.createElement("a")
                  link.href = fetchedDocument.file
                  link.download = fetchedDocument.file.substr(
                    fetchedDocument.file.lastIndexOf("/") + 1,
                  )
                  link.click()
                }}
              >
                Download
              </Button>
              <Button onClick={onClose}>Close</Button>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  )
}
