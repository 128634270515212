import { useNavigate, useParams } from "react-router-dom"
import { AppState, Auth0Provider, User } from "@auth0/auth0-react"
import queryString from "query-string"

type Props = {
  children: JSX.Element
}

export default ({ children, ...props }: Props) => {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onRedirectCallback = (appState?: AppState, _user?: User) => {
    if (appState && appState.returnTo) {
      const split = appState.returnTo?.split("?")
      const params = appState?.params ? appState?.params.split("?") : []
      let search = split.length > 1 ? split[1] : ""
      if (params.length > 1) {
        search = search + params[1]
      }
      console.log("search", search)
      console.log("pathname", split[0])
      navigate({
        pathname: split[0],
        search,
      })
    } else {
      console.log("default pathname", window.location.pathname)
      navigate(window.location.pathname)
    }
  }

  console.log("pathname in func", window.location.pathname)
  console.log("hostname", window.location.hostname)
  console.log("origin", window.location.origin)

  let redirect_uri = window.location.origin
  if (
    window.location.pathname.includes("/trade-credit/base") ||
    window.location.pathname.includes("/cod/base")
  ) {
    redirect_uri = window.location.origin + window.location.pathname
  } else if (
    window.location.pathname.includes("/trade-credit/") ||
    window.location.pathname.includes("/cod/")
  ) {
    const type = window.location.pathname.includes("/trade-credit/")
      ? "trade-credit"
      : "cod"
    const params = queryString.parse(window.location.search)
    params["type"] = type
    const path_parts = window.location.pathname.split("/")
    const application_id = path_parts[path_parts.length - 1]
    if (application_id.includes("?")) {
      params["application_id"] = application_id.split("?")[0]
    } else {
      params["application_id"] = application_id
    }

    redirect_uri =
      window.location.origin +
      "/trade-credit/redirect?" +
      queryString.stringify(params)
  }

  console.log("redirect_uri", redirect_uri)

  return (
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      authorizationParams={{
        ui_locales:
          // if hostname is XX.my.netnow.io
          ["fr", "es"].includes(window.location.hostname.split(".")[0])
            ? window.location.hostname.split(".")[0]
            : "en",
        redirect_uri,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      }}
      cacheLocation={
        ["development", "test"].includes(process.env.NODE_ENV)
          ? "localstorage"
          : "memory"
      }
      {...props}
    >
      {children}
    </Auth0Provider>
  )
}
