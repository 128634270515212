import { Box, Chip, Typography, Divider } from "@mui/material"
import WarningIcon from "@mui/icons-material/Warning"
import { AccountDelinquencyDetail } from "src/types/vendors.types"

interface DelinquencyDetailsSectionProps {
  delinquencyDetails: AccountDelinquencyDetail[] | AccountDelinquencyDetail
  formatDate: (date?: string) => string
}

export const DelinquencyDetailsSection = ({
  delinquencyDetails,
  formatDate,
}: DelinquencyDetailsSectionProps) => {
  const delinquencyDetailsArray = Array.isArray(delinquencyDetails)
    ? delinquencyDetails
    : [delinquencyDetails]
  return (
    <>
      <Divider sx={{ my: 2 }} />
      <Typography color="text.secondary" gutterBottom>
        Delinquency Details
      </Typography>
      <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
        {delinquencyDetailsArray.map((detail, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="subtitle2">
              {detail.subscriber?.name?.unparsed} - {detail.portfolioType} -{" "}
              {detail.accountNumber}
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mt: 1 }}>
              <Chip
                icon={<WarningIcon />}
                label={`Last Delinquent: ${formatDate(
                  detail?.mostRecentDelinquency?.date?.["#text"],
                )}`}
                color="warning"
                variant="outlined"
                size="small"
              />
              <Chip
                icon={<WarningIcon />}
                label={`Rating Code: ${detail.mostRecentDelinquency?.accountRatingCode}`}
                variant="outlined"
                size="small"
              />
              <Chip
                icon={<WarningIcon />}
                label={`Total Count: ${detail.totalDelinquencyCount}`}
                color="warning"
                variant="outlined"
                size="small"
              />
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}
