import { Card, CardContent, Typography, Box } from "@mui/material"
import {
  Timeline,
  TimelineItem,
  TimelineContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
} from "@mui/lab"
import LocationOnIcon from "@mui/icons-material/LocationOn"

interface Address {
  status: string
  street?: {
    name?: string
    type?: string
    number?: string
    preDirectional?: string
  }
  location?: {
    city?: string
    state?: string
    zipCode?: string
    zipExtension?: string
  }
  dateReported?: {
    "#text"?: string
  }
}

interface AddressHistoryCardProps {
  addresses: Address[] | Address
}

const AddressHistoryCard = ({ addresses }: AddressHistoryCardProps) => {
  const formatAddress = (address: Address) => {
    const street = address.street
    const location = address.location
    const streetAddress = [
      street?.preDirectional,
      street?.number,
      street?.name,
      street?.type,
    ]
      .filter(Boolean)
      .join(" ")

    return `${streetAddress}, ${location?.city}, ${location?.state} ${location?.zipCode}${
      location?.zipExtension ? `-${location?.zipExtension}` : ""
    }`
  }

  let addressArray = []
  if (Array.isArray(addresses)) {
    addressArray = addresses
  } else {
    addressArray = [addresses]
  }

  return (
    <Card elevation={2}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <LocationOnIcon sx={{ mr: 1 }} />
          <Typography variant="h6">Address History</Typography>
        </Box>

        <Timeline>
          {addressArray.map((address, index) => (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot
                  color={address.status === "current" ? "primary" : "grey"}
                />
                {index < addressArray.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                <Typography
                  variant="subtitle2"
                  color={
                    address.status === "current" ? "primary" : "text.primary"
                  }
                >
                  {address.status === "current" ? "Current: " : ""}
                  {formatAddress(address)}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Reported:{" "}
                  {address.dateReported?.["#text"]
                    ? new Date(
                        address.dateReported?.["#text"],
                      ).toLocaleDateString()
                    : ""}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </CardContent>
    </Card>
  )
}

export default AddressHistoryCard
