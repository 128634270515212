import { QueryClient } from "react-query"
import {
  CashApplication,
  CashCustomFieldResponse,
  TemplateCustomField,
} from "src/types"
import { useTypedMutation } from "../useTypedMutation"
import { FIELD_TYPES } from "src/statics"

export function usePatchBuyerCashApplication(
  onSuccess?: (data: CashApplication) => void,
) {
  const ENDPOINT = "/cod/base/save/"
  const patch = useTypedMutation<FormData>("patch/cod/base/save", onSuccess)

  const execute = (
    id: string,
    body: Partial<CashApplication>,
    onSuccess?: () => void,
    onError?: () => void,
  ) => {
    const formData = new FormData()
    for (const item of Object.keys(body)) {
      if (item === "files" && body["files"]) {
        body["files"].forEach((file, index) => {
          if (file instanceof File) {
            formData.append("file" + index, file as File)
          } else {
            formData.append("file" + index, JSON.stringify(file))
          }
        })
      } else if (item.includes("data")) {
        const data = body["data"]
        if (data) {
          if (data["customFields"]) {
            // const existingCustomResponses = new Array<CustomFieldResponse>()
            const customFields = data[
              "customFields"
            ] as Array<CashCustomFieldResponse>
            Object.entries(customFields).forEach(([key, value]) => {
              if (
                (value.field as TemplateCustomField).fieldType ===
                FIELD_TYPES.FILE
              ) {
                // do nothing
                // it will be handled with `filesToUpload`
                // this is to prevent the file from being uploaded twice
                // this field is used for data validation of formik still.
              } else if (
                (value.field as TemplateCustomField).fieldType ===
                FIELD_TYPES.ADDRESS
              ) {
                if (value.responseJson) {
                  formData.append(key, JSON.stringify(value.responseJson))
                } else {
                  formData.append(key, JSON.stringify({}))
                }
              } else {
                formData.append(key, value.response || "")
              }
            })
          }

          delete data["customFields"]

          if (data["filesToUpload"]) {
            const fileMap = data["filesToUpload"] as Map<string, File[]>
            fileMap.forEach((files, key) => {
              files.forEach((file, index) => {
                formData.append(key + "$" + index, file as File)
              })
            })
            delete data["filesToUpload"]
          }

          if (
            data["customResponsesToBeDeleted"] &&
            Object.keys(data["customResponsesToBeDeleted"]).length > 0
          ) {
            data["customResponsesToBeDeleted"] = JSON.stringify(
              data["customResponsesToBeDeleted"],
            )
          } else if (data["customResponsesToBeDeleted"]) {
            delete data["customResponsesToBeDeleted"]
          }
          formData.append(item, JSON.stringify(data))
        }
      } else if (item.includes("Date")) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(
          item,
          new Date(body[item as keyof CashApplication] as Date).toISOString(),
        )
      } else if (item === "salesRep") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof CashApplication]?.id)
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formData.append(item, body[item as keyof CashApplication])
      }
    }
    patch.mutate(
      {
        endpoint: ENDPOINT + id + "/",
        method: "patch",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      {
        onSuccess: async () => {
          await new QueryClient().invalidateQueries({
            queryKey: [ENDPOINT.replaceAll("/", "")],
          })
          if (onSuccess) onSuccess()
        },
        onError,
      },
    )
  }
  return { ...patch, execute }
}
