import { useState } from "react"
import { ArrowForward } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"

import {
  Box,
  Checkbox,
  Typography,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  useMediaQuery,
} from "@mui/material"
import DebouncedAutocomplete from "src/components/autocomplete/DebouncedAutocomplete"
import { useBusinessSearch } from "src/queries/vendors/useBusinessSearch"
import { ApplicationTemplate, Application } from "src/types"

import Address from "../components/Address"
import ReusableCustomQuestions from "../components/ReusableCustomQuestions"
import { useSalesReps } from "src/queries/base/useSalesReps"
import { useCreditManagers } from "src/queries/base/useCreditManagers"
import {
  BUSINESS_CONFIG_BILLING_ADDRESS,
  BUSINESS_CONFIG_BUSINESS_ADDRESS,
  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
  BUSINESS_CONFIG_SERVICE_ADDRESS,
  BUSINESS_CONFIG_SHIPPING_ADDRESS,
  FORM_INPUT_NAMES,
  PAGE_LABEL_BUSINESS_DETAILS,
  PAGE_LABEL_PROJECT_DETAILS,
} from "./constants"
import {
  APPLICANT_TYPES,
  APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME,
  customWordMapping,
} from "src/statics"
import {
  getAddressTypes,
  getCreditManagerSelectorEnabled,
  getRevenueEnabled,
  getStoreEnabled,
  getStoreRequired,
} from "./schemas/CompanyDetailsSectionSchema"

import FormLabel from "src/components/label/FormLabel"
import MobileNavButtonsBlock from "src/components/mobile-nav-buttons-block/MobileNavButtonsBlock"

import { isProjectApplication } from "../utils"
import { useAnonymousBusiness } from "src/queries/credit/useAnonymousBusiness"

type Props = {
  application?: Application
  data: Application["data"]
  errors: Application["data"] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (key: string, value: any) => void
  onCustomChange: (key: string, value: any) => void
  onContinue: (validateSalesRep: boolean, validateStore: boolean) => void
  onApplicationFieldUpdated?: (key: string, value: any) => void
  topLevelValues?: Application
  template?: ApplicationTemplate
  setFilesInMemory: (files: Map<string, File[]>) => void
  filesInMemory: Map<string, File[]>
  handleBack: () => void
  activeStep: number
}

export default ({
  application,
  data,
  errors,
  onChange,
  onCustomChange,
  onContinue,
  onApplicationFieldUpdated,
  topLevelValues,
  template,
  filesInMemory,
  setFilesInMemory,
  handleBack,
  activeStep,
}: Props) => {
  const [hideShippingAddress, setHideShippingAddress] = useState(false)
  const [hideBillingAddress, setHideBillingAddress] = useState(false)

  const [hideCcBillingAddress, setHideCcBillingAddress] = useState(false)

  const [hideDba, setHideDba] = useState(false)

  const { data: salesReps } = useSalesReps(
    application?.seller?.id || "",
    topLevelValues?.store,
  )

  const { data: creditManagers } = useCreditManagers(
    application?.seller?.id || "",
  )

  const matches = useMediaQuery("(max-width: 899px)")
  const matchesXL = useMediaQuery("(max-width: 1199px)")

  const { data: businessData } = useAnonymousBusiness(
    application?.seller?.id || "",
  )

  const salesRepEnabled = salesReps && salesReps.length > 0
  const storeRequired =
    businessData?.stores &&
    businessData?.stores.length > 1 &&
    getStoreEnabled(template) &&
    getStoreRequired(template)

  return (
    <>
      {!matchesXL && (
        <Typography component="h1" variant="h5" style={{ marginTop: "8px" }}>
          {isProjectApplication(data)
            ? PAGE_LABEL_PROJECT_DETAILS
            : PAGE_LABEL_BUSINESS_DETAILS}
        </Typography>
      )}

      <Typography variant="body2" style={{ margin: "8px 0 8px 0" }}>
        {isProjectApplication(data)
          ? "Let us know about your project and where it is located."
          : "Let us know about your business and where it is located."}
      </Typography>
      {!matches && <Divider style={{ margin: "16px 0 16px 0" }} />}
      {APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME.includes(
        data.applicantType,
      ) && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: "5%",
              alignItems: matches ? "" : "center",
            }}
          >
            <FormControl margin="normal" sx={{ width: "100%" }}>
              <FormLabel
                labelName="Legal Business Name"
                idFor="business-name-search"
                required
              />
              <DebouncedAutocomplete
                id="business-name-search"
                useSuggestions={useBusinessSearch}
                style={{ width: "100%" }}
                placeholder="Legal Business Name"
                required
                inputProps={{
                  id: "legal-business-name",
                  style: { height: "56px" },
                }}
                value={data.legalBusinessName}
                onChange={(val) => {
                  onChange(FORM_INPUT_NAMES.LEGAL_BUSINESS_NAME, val)
                  if (hideDba) {
                    onChange(FORM_INPUT_NAMES.BUSINESS_DBA, val)
                  }
                }}
                error={Boolean(errors?.legalBusinessName)}
              />
              {matches && (
                <FormGroup>
                  <FormControlLabel
                    defaultChecked={true}
                    control={
                      <Checkbox
                        id="dba-same"
                        onChange={(event) => {
                          setHideDba(event.target.checked)
                          if (event.target.checked) {
                            onChange(
                              FORM_INPUT_NAMES.BUSINESS_DBA,
                              data.legalBusinessName,
                            )
                          } else {
                            onChange(FORM_INPUT_NAMES.BUSINESS_DBA, undefined)
                          }
                        }}
                      />
                    }
                    label="DBA same as legal business name"
                  />
                </FormGroup>
              )}
            </FormControl>
            {!hideDba && (
              <FormControl margin="normal" sx={{ width: "100%" }}>
                <FormLabel
                  labelName="Doing Business As / Trade Name"
                  required
                  idFor="business-dba"
                />
                <TextField
                  id="business-dba"
                  fullWidth
                  placeholder={matches ? "Doing Business As / Trade Name" : ""}
                  label={matches ? "" : "Doing Business As / Trade Name"}
                  type="text"
                  value={data.businessDba}
                  onChange={(event) => {
                    onChange(FORM_INPUT_NAMES.BUSINESS_DBA, event.target.value)
                  }}
                  inputProps={{
                    style: { height: "56px" },
                  }}
                  error={Boolean(errors?.businessDba)}
                />
              </FormControl>
            )}
          </Box>
          {!matches && (
            <FormGroup>
              <FormControlLabel
                defaultChecked={true}
                control={
                  <Checkbox
                    id="dba-same"
                    onChange={(event) => {
                      setHideDba(event.target.checked)
                      if (event.target.checked) {
                        onChange(
                          FORM_INPUT_NAMES.BUSINESS_DBA,
                          data.legalBusinessName,
                        )
                      } else {
                        onChange(FORM_INPUT_NAMES.BUSINESS_DBA, undefined)
                      }
                    }}
                  />
                }
                label="DBA same as legal business name"
              />
            </FormGroup>
          )}
        </>
      )}
      {getAddressTypes(template).includes(BUSINESS_CONFIG_BUSINESS_ADDRESS) && (
        <>
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <Typography component="label" variant="subtitle1" htmlFor="">
              {customWordMapping("Mailing Address", template)} *
            </Typography>

            <Address
              keyPrefix="business"
              data={data}
              onChange={onChange}
              errors={errors}
              defaults={{ country: application?.seller?.country || "" }}
            />
          </FormControl>
        </>
      )}
      {getAddressTypes(template).includes(BUSINESS_CONFIG_BILLING_ADDRESS) && (
        <>
          <Typography variant="subtitle1">Billing Address *</Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="billing-same-as-mailing"
                  onChange={(event) => {
                    setHideBillingAddress(event.target.checked)
                    if (event.target.checked) {
                      onChange("billingAddress", data.businessAddress)
                      onChange("billingCity", data.businessCity)
                      onChange("billingCountry", data.businessCountry)
                      onChange("billingRegion", data.businessRegion)
                      onChange("billingPostCode", data.businessPostCode)
                    } else {
                      onChange("billingAddress", undefined)
                      onChange("billingCity", undefined)
                      onChange("billingCountry", undefined)
                      onChange("billingRegion", undefined)
                      onChange("billingPostCode", undefined)
                    }
                  }}
                />
              }
              label={`${customWordMapping(
                "Billing address",
                template,
              )} same as ${customWordMapping("Mailing Address", template)}`}
            />
          </FormGroup>

          {!hideBillingAddress && (
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <Address
                keyPrefix="billing"
                data={data}
                onChange={onChange}
                errors={errors}
                defaults={{ country: application?.seller?.country || "" }}
              />
            </FormControl>
          )}
        </>
      )}
      {getAddressTypes(template).includes(BUSINESS_CONFIG_SHIPPING_ADDRESS) && (
        <>
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            {!hideShippingAddress ? (
              <Typography variant="subtitle1">
                {customWordMapping("Shipping Address", template)} *
              </Typography>
            ) : (
              !matches && (
                <Typography variant="subtitle1">
                  {customWordMapping("Shipping Address", template)} *
                </Typography>
              )
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="shipping-same-as-mailing"
                    onChange={(event) => {
                      setHideShippingAddress(event.target.checked)
                      if (event.target.checked) {
                        onChange(
                          FORM_INPUT_NAMES.SHIPPING_ADDRESS,
                          data.businessAddress,
                        )
                        onChange(
                          FORM_INPUT_NAMES.SHIPPING_CITY,
                          data.businessCity,
                        )
                        onChange(
                          FORM_INPUT_NAMES.SHIPPING_COUNTRY,
                          data.businessCountry,
                        )
                        onChange(
                          FORM_INPUT_NAMES.SHIPPING_REGION,
                          data.businessRegion,
                        )
                        onChange(
                          FORM_INPUT_NAMES.SHIPPING_POST_CODE,
                          data.businessPostCode,
                        )
                      } else {
                        onChange(FORM_INPUT_NAMES.SHIPPING_ADDRESS, undefined)
                        onChange(FORM_INPUT_NAMES.SHIPPING_CITY, undefined)
                        onChange(FORM_INPUT_NAMES.SHIPPING_COUNTRY, undefined)
                        onChange(FORM_INPUT_NAMES.SHIPPING_REGION, undefined)
                        onChange(FORM_INPUT_NAMES.SHIPPING_POST_CODE, undefined)
                      }
                    }}
                  />
                }
                label={`${customWordMapping(
                  "Shipping address",
                  template,
                )} same as ${customWordMapping("Mailing Address", template)}`}
              />
            </FormGroup>

            {!hideShippingAddress && (
              <Address
                keyPrefix="shipping"
                data={data}
                onChange={onChange}
                errors={errors}
                defaults={{ country: application?.seller?.country || "" }}
              />
            )}
          </FormControl>
        </>
      )}

      {getAddressTypes(template).includes(BUSINESS_CONFIG_SERVICE_ADDRESS) && (
        <>
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <Typography variant="subtitle1">Service Address *</Typography>

            <Address
              keyPrefix="service"
              data={data}
              onChange={onChange}
              errors={errors}
              defaults={{ country: application?.seller?.country || "" }}
            />
          </FormControl>
        </>
      )}

      {getAddressTypes(template).includes(
        BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
      ) && (
        <>
          <FormControl
            sx={{ width: "100%" }}
            margin={matches ? "normal" : "none"}
          >
            <Typography variant="subtitle1">
              Credit Card Billing Address *
            </Typography>
            {!hideCcBillingAddress && (
              <Address
                keyPrefix="creditCardBilling"
                data={data}
                onChange={onChange}
                errors={errors}
                defaults={{ country: application?.seller?.country || "" }}
              />
            )}
          </FormControl>
        </>
      )}

      {![APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER].includes(
        data.applicantType,
      ) &&
        getRevenueEnabled(template) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: "5%",
              alignItems: matches ? "" : "center",
              margin: "16px 0 16px 0",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              What is the annual revenue of your business? *
            </Typography>
            <FormControl
              fullWidth
              margin={matches ? "normal" : "none"}
              style={{ flex: "1 1 0px" }}
              required
            >
              {!matches && (
                <InputLabel id="revenue-label" required>
                  Revenue
                </InputLabel>
              )}
              <Select
                labelId="revenue-label"
                id="revenue-select"
                value={data.revenue}
                label={matches ? "" : "Revenue"}
                onChange={(event) => {
                  onChange(FORM_INPUT_NAMES.REVENUE, event.target.value)
                }}
                style={{ height: "56px" }}
                error={Boolean(errors?.revenue)}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    return (
                      <Typography sx={{ color: "text.disabled" }}>
                        Select
                      </Typography>
                    )
                  }

                  return selected
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {[
                  "Less than $100,000 / year",
                  "$100,000 - $500,000 / year",
                  "$500,000 - $1M / year",
                  "$1M - $2.5M / year",
                  "$2.5M - $10M / year",
                  "$10M - $50M / year",
                  "More than $50M / year",
                  "Not a business",
                ].map((revenue) => (
                  <MenuItem value={revenue} key={revenue}>
                    {revenue}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated &&
        topLevelValues &&
        businessData?.stores &&
        businessData?.stores.length > 1 &&
        getStoreEnabled(template) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: "5%",
              alignItems: matches ? "" : "center",
              margin: "16px 0 16px 0",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              {`${customWordMapping(
                "Select the store/location you are applying for",
                template,
              )}${getStoreRequired(template) ? "*" : ""}`}
            </Typography>
            <FormControl
              fullWidth
              margin={matches ? "normal" : "none"}
              style={{ flex: "1 1 0px" }}
              required={getStoreRequired(template)}
            >
              {!matches && (
                <InputLabel id="store-label">
                  {customWordMapping("Store", template)}
                </InputLabel>
              )}
              <Select
                labelId="store-label"
                id="store-select"
                label={matches ? "" : customWordMapping("Store", template)}
                value={topLevelValues?.store}
                onChange={(event) => {
                  const newBusinessId =
                    businessData?.stores?.find(
                      (store) => store.id === event.target.value,
                    )?.id || undefined
                  if (newBusinessId != topLevelValues?.store) {
                    onApplicationFieldUpdated(
                      FORM_INPUT_NAMES.SALES_REP,
                      salesReps?.find(
                        (salesRep) => salesRep.id === event.target.value,
                      ) || { id: -1 },
                    )
                  }
                  onApplicationFieldUpdated(
                    FORM_INPUT_NAMES.STORE,
                    newBusinessId,
                  )
                }}
                error={Boolean(errors?.store)}
                style={{ height: "56px" }}
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    return (
                      <Typography sx={{ color: "text.disabled" }}>
                        Select
                      </Typography>
                    )
                  }
                  const store = businessData?.stores?.find(
                    (s) => s.id === selected,
                  )
                  return `${store?.name}`
                }}
              >
                {businessData?.stores
                  ?.sort((a, b) => {
                    if (!a.name) {
                      return 1
                    }
                    if (!b.name) {
                      return -1
                    }
                    return a.name < b.name ? -1 : 1
                  })
                  ?.map((store) => (
                    <MenuItem value={store.id} key={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        salesRepEnabled && (
          <Box
            sx={{
              display: "flex",
              flexDirection: matches ? "column" : "row",
              width: "100%",
              gap: "5%",
              alignItems: matches ? "" : "center",
              margin: "16px 0 16px 0",
            }}
          >
            <Typography style={{ flex: "1 1 0px" }}>
              {customWordMapping(
                "Who is the Sales Representative associated with this account?",
                template,
              )}
            </Typography>
            <FormControl
              fullWidth
              margin={matches ? "normal" : "none"}
              style={{ flex: "1 1 0px" }}
              required
            >
              {!matches && (
                <InputLabel id="sales-rep-label">Sales Rep</InputLabel>
              )}
              <Select
                labelId="sales-rep-label"
                id="sales-rep-select"
                label={matches ? "" : "Sales Rep"}
                value={topLevelValues?.salesRep?.id}
                onChange={(event) => {
                  onApplicationFieldUpdated(
                    FORM_INPUT_NAMES.SALES_REP,
                    salesReps?.find(
                      (salesRep) => salesRep.id === event.target.value,
                    ) || { id: -1 },
                  )
                }}
                error={Boolean(errors?.salesRep)}
                style={{ height: "56px" }}
                renderValue={(selected) => {
                  if (selected === undefined || selected === "") {
                    return (
                      <Typography sx={{ color: "text.disabled" }}>
                        Select
                      </Typography>
                    )
                  }
                  if (String(selected) === "-1") {
                    return <Typography>No Sales Rep / Other</Typography>
                  }
                  const rep = salesReps?.find(
                    (salesRep) => salesRep.id === selected,
                  )
                  return `${rep?.firstName} ${rep?.lastName}`
                }}
              >
                {salesReps
                  ?.sort((a, b) => {
                    if (!a.firstName) {
                      return 1
                    }
                    if (!b.firstName) {
                      return -1
                    }
                    return a.firstName < b.firstName ? -1 : 1
                  })
                  ?.map((salesRep) => (
                    <MenuItem value={salesRep.id} key={salesRep.id}>
                      {salesRep.firstName} {salesRep.lastName}
                    </MenuItem>
                  ))}
                <MenuItem value={-1} key="sales_rep_0">
                  No Sales Rep / Other
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      {onApplicationFieldUpdated !== undefined &&
        topLevelValues !== undefined &&
        getCreditManagerSelectorEnabled(template) &&
        creditManagers && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "5%",
              alignItems: "center",
            }}
          >
            <FormControl
              sx={{ width: "100%" }}
              margin={matches ? "normal" : "none"}
            >
              <Typography style={{ flex: "1 1 0px" }}>
                Who is the Credit Manager associated with this account?
              </Typography>
              <FormControl
                fullWidth
                margin="normal"
                style={{ flex: "1 1 0px" }}
              >
                {!matches && (
                  <InputLabel id="credit-manager-label">
                    Credit Manager
                  </InputLabel>
                )}
                <Select
                  labelId="credit-manager-label"
                  id="credit-manager-select"
                  label={matches ? "" : "Credit Manager"}
                  placeholder="Credit Manager"
                  value={topLevelValues?.assignee?.id}
                  onChange={(event) => {
                    onApplicationFieldUpdated(
                      FORM_INPUT_NAMES.ASSIGNEE,
                      creditManagers?.find(
                        (creditManager) =>
                          creditManager.id === event.target.value,
                      ) || { id: -1 },
                    )
                  }}
                  error={Boolean(errors?.creditManager)}
                  style={{ height: "56px" }}
                  renderValue={(selected) => {
                    if (selected === undefined || selected === "") {
                      return (
                        <Typography sx={{ color: "text.disabled" }}>
                          Select
                        </Typography>
                      )
                    }
                    if (String(selected) === "-1") {
                      return <Typography>Unknown / Other</Typography>
                    }
                    const cm = creditManagers?.find(
                      (creditManager) => creditManager.id === selected,
                    )
                    return `${cm?.firstName} ${cm?.lastName}`
                  }}
                >
                  {creditManagers
                    ?.sort((a, b) => {
                      return a.firstName < b.firstName ? -1 : 1
                    })
                    ?.map((creditManager) => (
                      <MenuItem value={creditManager.id} key={creditManager.id}>
                        {creditManager.firstName} {creditManager.lastName}
                      </MenuItem>
                    ))}
                  <MenuItem value={-1} key="sales_rep_0">
                    Unknown / Other
                  </MenuItem>
                </Select>
              </FormControl>
            </FormControl>
          </Box>
        )}
      {template?.customFields && (
        <ReusableCustomQuestions
          application={application}
          associatedPage={"Business Details"}
          data={data["customFields"]}
          dataKeyResponses={data}
          errors={errors}
          onChange={onCustomChange}
          overrideHeight
          filesInMemory={filesInMemory}
          setFilesInMemory={setFilesInMemory}
        />
      )}
      {matches ? (
        <MobileNavButtonsBlock
          onContinue={() => {
            onContinue(Boolean(salesRepEnabled), Boolean(storeRequired))
          }}
          handleBack={handleBack}
          activeStep={activeStep}
          click={() => {
            if (hideShippingAddress) {
              // the user can change the address after checking the box.
              // in that case, the changes don't propagate. and we should sync here.
              onChange("shippingAddress", data.businessAddress)
              onChange("shippingCity", data.businessCity)
              onChange("shippingCountry", data.businessCountry)
              onChange("shippingRegion", data.businessRegion)
              onChange("shippingPostCode", data.businessPostCode)
            }
            if (hideBillingAddress) {
              // the user can change the address after checking the box.
              // in that case, the changes don't propagate. and we should sync here.
              onChange("billingAddress", data.businessAddress)
              onChange("billingCity", data.businessCity)
              onChange("billingCountry", data.businessCountry)
              onChange("billingRegion", data.businessRegion)
              onChange("billingPostCode", data.businessPostCode)
            }
            onContinue(Boolean(salesRepEnabled), Boolean(storeRequired))
          }}
        />
      ) : (
        <LoadingButton
          id="continue-business"
          onClick={() => {
            if (hideShippingAddress) {
              // the user can change the address after checking the box.
              // in that case, the changes don't propagate. and we should sync here.
              onChange("shippingAddress", data.businessAddress)
              onChange("shippingCity", data.businessCity)
              onChange("shippingCountry", data.businessCountry)
              onChange("shippingRegion", data.businessRegion)
              onChange("shippingPostCode", data.businessPostCode)
            }
            if (hideBillingAddress) {
              // the user can change the address after checking the box.
              // in that case, the changes don't propagate. and we should sync here.
              onChange("billingAddress", data.businessAddress)
              onChange("billingCity", data.businessCity)
              onChange("billingCountry", data.businessCountry)
              onChange("billingRegion", data.businessRegion)
              onChange("billingPostCode", data.businessPostCode)
            }
            onContinue(Boolean(salesRepEnabled), Boolean(storeRequired))
          }}
          size="large"
          fullWidth
          endIcon={<ArrowForward />}
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: "none" }}
        >
          Save & Continue
        </LoadingButton>
      )}
    </>
  )
}
