import { Box, Card, CardContent, Chip, Grid, Typography } from "@mui/material"
import PaymentsIcon from "@mui/icons-material/Payments"
import TimelineIcon from "@mui/icons-material/Timeline"

type Account = {
  terms?: {
    scheduledMonthlyPayment?: string
  }
  subscriber?: {
    name?: {
      unparsed?: string
    }
  }
  pastDue?: string
  portfolioType?: string
  currentBalance?: string
  creditLimit?: string
  highCredit?: string
  accountNumber?: string
  accountRating?: string
  dateOpened?: {
    "#text"?: string
  }
  dateEffective?: {
    "#text"?: string
  }
  mostRecentPayment?: {
    amount?: string
  }
}
interface AccountDetailsSectionProps {
  accounts: Array<Account> | Account
  formatCurrency: (amount?: string) => string
  formatDate: (date?: string) => string
}

export const AccountDetailsSection = ({
  accounts,
  formatCurrency,
  formatDate,
}: AccountDetailsSectionProps) => {
  let accountsArray = []
  if (Array.isArray(accounts)) {
    accountsArray = accounts
  } else {
    accountsArray = [accounts]
  }
  return (
    <Grid container spacing={2}>
      {accountsArray?.map((account, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2" gutterBottom>
                {account.subscriber?.name?.unparsed}
              </Typography>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <Chip
                  icon={<PaymentsIcon />}
                  label={`Monthly Payment: ${formatCurrency(
                    account.terms?.scheduledMonthlyPayment,
                  )}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={`Balance: ${formatCurrency(account.currentBalance)}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={`Most Recent Payment: ${formatCurrency(
                    account.mostRecentPayment?.amount,
                  )}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={`Past Due: ${formatCurrency(account.pastDue)}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={`High Credit: ${formatCurrency(account.highCredit)}`}
                  size="small"
                  variant="outlined"
                />
                {account.creditLimit && (
                  <Chip
                    label={`Credit Limit: ${formatCurrency(
                      account.creditLimit,
                    )}`}
                    size="small"
                    variant="outlined"
                  />
                )}
                <Chip
                  label={`Account Type: ${account.portfolioType}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  icon={<TimelineIcon />}
                  label={`Opened: ${formatDate(account.dateOpened?.["#text"])}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  icon={<TimelineIcon />}
                  label={`Date Effective: ${formatDate(
                    account.dateEffective?.["#text"],
                  )}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={`Account Number: ${account.accountNumber}`}
                  size="small"
                  variant="outlined"
                />
                <Chip
                  label={`Account Rating: ${account.accountRating}`}
                  size="small"
                  variant="outlined"
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
