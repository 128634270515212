import * as yup from "yup"
import { Application, ApplicationTemplate } from "src/types"
import {
  BUSINESS_CONFIG_BUSINESS_ADDRESS,
  BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS,
  BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR,
  BUSINESS_CONFIG_REVENUE,
  BUSINESS_CONFIG_SERVICE_ADDRESS,
  BUSINESS_CONFIG_SHIPPING_ADDRESS,
  BUSINESS_CONFIG_STORE,
  FORM_INPUT_NAMES,
  PAGE_LABEL_BUSINESS_DETAILS,
} from "../constants"
import {
  APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME,
  APPLICANT_TYPES,
  customWordMapping,
  FIELD_TYPES,
} from "src/statics"
import { STATES, PROVINCES } from "../../components/Address"
import {
  addConditionalValidationstoCustomSchema,
  hasConditions,
} from "../../utils"
import { addAddressToSchema } from "../../components/CustomAddress"

const baseYup = (template: ApplicationTemplate) =>
  yup.object({
    legalBusinessName: yup.string().when(FORM_INPUT_NAMES.APPLICANT_TYPE, {
      is: (fieldTwo: string) =>
        APPLICANT_TYPES_OPTIONS_REQUIRES_BUSINESS_NAME.includes(fieldTwo),
      then: yup.string().required(),
    }),
    revenue: yup.string().when(FORM_INPUT_NAMES.APPLICANT_TYPE, {
      is: (fieldTwo: string) => {
        return (
          fieldTwo !== APPLICANT_TYPES.INDIVIDUAL_CONSUMER_HOMEOWNER &&
          getRevenueEnabled(template)
        )
      },
      then: yup.string().required(),
    }),
  })

export const getCompanyDetailsEnabledSchema = (
  template: ApplicationTemplate,
  data: Application["data"] = {},
): yup.AnyObjectSchema => {
  let enabledSchema = baseYup(template)
  template.formTemplate.pages
    .find((step) => step.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.filter((config) => !!config.value)
    .forEach(
      (config) =>
        (enabledSchema = enabledSchema.concat(
          getAdditionalSchema(config.label),
        )),
    )

  const addresses = getAddressTypes(template)

  if (addresses.includes(BUSINESS_CONFIG_SHIPPING_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        shippingAddress: yup
          .string()
          .required(
            `${customWordMapping("Shipping Address", template)} is required`,
          ),
        shippingCity: yup.string().required("City is required"),
        shippingCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        shippingRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        shippingPostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_SERVICE_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        serviceAddress: yup.string().required("Service Address is required"),
        serviceCity: yup.string().required("City is required"),
        serviceCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        serviceRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        servicePostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_CREDIT_CARD_BILLING_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        creditCardBillingAddress: yup.string().required("Address is required"),
        creditCardBillingCity: yup.string().required("City is required"),
        creditCardBillingCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        creditCardBillingRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        creditCardBillingPostCode: yup
          .string()
          .required("Post code is required"),
      }),
    )
  }

  if (addresses.includes(BUSINESS_CONFIG_BUSINESS_ADDRESS)) {
    enabledSchema = enabledSchema.concat(
      yup.object({
        businessAddress: yup.string().required("Address is required"),
        businessCity: yup.string().required("City is required"),
        businessCountry: yup
          .string()
          .required("Country is required")
          .oneOf(["Canada", "United States"]),
        businessRegion: yup
          .string()
          .required()
          .oneOf([...STATES, ...PROVINCES]),
        businessPostCode: yup.string().required("Post code is required"),
      }),
    )
  }

  let customSchema = yup.object({})
  template.customFields
    ?.filter((field) => field.associatedPage === PAGE_LABEL_BUSINESS_DETAILS)
    .filter((field) => field.required && !!field.id)
    .filter((field) => !hasConditions(field))
    .forEach((field) => {
      if (field.fieldType === "file") {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup.mixed().required("Please upload a file"),
          }),
        )
      } else if (field.fieldType === FIELD_TYPES.ADDRESS) {
        customSchema = addAddressToSchema(customSchema, field)
      } else {
        customSchema = customSchema.concat(
          yup.object({
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            [field.id!]: yup
              .object({
                response: yup.string().required("Field is required"),
              })
              .required("Field is required"),
          }),
        )
      }
    })
  if (data) {
    customSchema = addConditionalValidationstoCustomSchema(
      template,
      customSchema,
      data,
      PAGE_LABEL_BUSINESS_DETAILS,
    )
  }
  enabledSchema = enabledSchema.concat(
    yup.object({
      customFields: customSchema,
    }),
  )
  return enabledSchema
}

export const getAdditionalSchema = (label: string) => {
  return yup.object({})
}

export const getAddressTypes = (template?: ApplicationTemplate) => {
  let fromTemplate = template?.formTemplate.pages
    ?.find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.filter((config) => config.label.includes("Address"))
    ?.filter((c) => c.value === true)
    ?.map((c) => c.label)

  if (!fromTemplate || fromTemplate.length === 0) {
    fromTemplate = [
      BUSINESS_CONFIG_BUSINESS_ADDRESS,
      BUSINESS_CONFIG_SHIPPING_ADDRESS,
    ]
  }
  return fromTemplate
}

export const getRevenueEnabled = (template?: ApplicationTemplate) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) => config.label.includes(BUSINESS_CONFIG_REVENUE))
    ?.value

  // by default it is enabled if not specified
  return v === undefined || Boolean(v)
}

export const getStoreEnabled = (template?: ApplicationTemplate) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) => config.label.includes(BUSINESS_CONFIG_STORE))
    ?.value

  // by default it is enabled if not specified
  return v === undefined || Boolean(v)
}

export const getStoreRequired = (template?: ApplicationTemplate) => {
  if (getStoreEnabled(template) === false) {
    return false
  }

  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) => config.label.includes(BUSINESS_CONFIG_STORE))
    ?.required

  // by default it is required if not specified
  return v === undefined ? true : Boolean(v)
}

export const getCreditManagerSelectorEnabled = (
  template?: ApplicationTemplate,
) => {
  const v = template?.formTemplate.pages
    .find((page) => page.label === PAGE_LABEL_BUSINESS_DETAILS)
    ?.config?.find((config) =>
      config.label.includes(BUSINESS_CONFIG_CREDIT_MANGER_SELECTOR),
    )?.value

  // by default it is enabled if not specified
  return Boolean(v)
}
