// component
import { Settings } from "@mui/icons-material"
import { Icon } from "@iconify/react"
import { Group } from "src/types"

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "credit applications",
    path: "/applications",
    icon: <Icon icon="mdi:hand-coin-outline" width={24} />,
  },
  {
    title: "Cash-on-Delivery (COD)",
    path: "/cash-applications",
    icon: <Icon icon="mdi:credit-card-outline" width={24} />,
  },
  {
    title: "settings",
    path: "/settings",
    icon: <Settings />,
  },
  // {
  //   title: "monitoring",
  //   path: "/monitoring",
  //   icon: <Icon icon="mdi:monitor-eye" width={24} />,
  // },
]

const getNavConfig = (user: any, businessServices: string[]) => {
  let config = navConfig
  if (user) {
    if (!user.groups.includes(Group.CreditManager)) {
      config = navConfig.filter((item) => item.title !== "settings")
    }
  }
  if (businessServices && !businessServices.includes("cod")) {
    config = navConfig.filter((item) => item.title !== "Cash-on-Delivery (COD)")
  }
  return config
}

export default getNavConfig
